import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import routesSlice from '../features/routes/routesSlice';
import shoperSlice from '../features/shoper/shoperSlice';
import loginSilce from '../features/login/loginSilce';
export const store = configureStore({
  reducer: {
    // counter: counterReducer,
    routes :routesSlice,
    shoper:shoperSlice,
    login:loginSilce
  },
});
