import { HashRouter,useLocation } from "react-router-dom";
import App from "../App";
import React, { Suspense, lazy } from "react"
import { Route, Routes } from "react-router-dom"

import LoadingRoute from "../components/LoadingRoute";

// 引入页面
const Home = lazy(() => import("../views/Home"))
const Layouts = lazy(() => import("../Layouts"))
const Error = lazy(() => import("../views/Error"))
const CheckOrder = lazy(() => import("../views/CheckOrder"))
const ReturnOrder = lazy(() => import("../views/ReturnOrder"))
const OvertimeOrder = lazy(() => import("../views/OvertimeOrder"))
const RejectOrder = lazy(() => import("../views/RejectOrder"))
const WorkingOrder = lazy(() => import("../views/WorkingOrder"))
const FinishOrder = lazy(() => import("../views/FinishOrder"))
const WorkerDetails = lazy(() => import("../views/WorkerDetails"))
const OrderDetails = lazy(() => import("../views/OrderDetails"))
const AcceptedWorker = lazy(() => import("../views/AcceptedWorker"))
const TodayOverTime = lazy(() => import("../views/TodayOvertime"))
const NewCheck = lazy(() => import("../views/NewCheck"))
const ShopOrder = lazy(() => import("../views/ShopOrder"))
const NewOrderDetails = lazy(() => import("../views/NewOrderDetails"))
const BeijingOrder = lazy(() => import("../views/BeijingOrder"))
const BeijingDetails = lazy(() => import("../views/BeijingDetails"))
const OutlineBeijingOrder = lazy(() => import("../views/OutlineBeijingOrder"))
const Kanban = lazy(() => import("../views/Kanban"))
const WaitCompleteOrder = lazy(() => import("../views/WaitCompleteOrder"))

const Router = () => {
    return (
        <HashRouter>
            <Suspense fallback={<div><LoadingRoute /></div>}>
                <Routes>
                    <Route path="" element={<App></App>}></Route>
                    <Route path="/" element={<Layouts />}>
                        <Route path="/home/:n/:s/:t" element={<Home/>}></Route>
                        <Route path="/check/:n/:s" element={<CheckOrder/>}></Route>
                        <Route path="/newcheck/:n/:s" element={<NewCheck/>}></Route>
                        <Route path="/return/:n/:s" element={<ReturnOrder/>}></Route>
                        <Route path="/reject/:n/:s" element={<RejectOrder />}></Route>
                        <Route path="/overtime/:n/:s" element={<OvertimeOrder/>}></Route>
                        <Route path="/working/:n/:s" element={<WorkingOrder/>}></Route>
                        <Route path="/finish/:n/:s" element={<FinishOrder />}></Route>
                        <Route path="/accept/:n/:s" element={<AcceptedWorker />}></Route>
                        <Route path="/work/details" element={<WorkerDetails />}></Route>
                        <Route path="/order/details" element={<OrderDetails />}></Route>
                        <Route path="/today/overtime" element={<TodayOverTime />}></Route>
                        <Route path="/shop/o/:n/:s" element={<ShopOrder />}></Route>
                        <Route path="/new/order/:n/:s" element={<NewOrderDetails />}></Route>
                        <Route path="/beijing/order/:n/:s" element={<BeijingOrder />}></Route>
                        <Route path="/beijing/details" element={<BeijingDetails />}></Route>
                        <Route path="/out/beijing/order/:n/:s" element={<OutlineBeijingOrder />}></Route>
                        <Route path="/kanban" element={<Kanban />}></Route>
                        <Route path="/waitcomplete/:n/:s" element={<WaitCompleteOrder/>}></Route>
                    </Route>
                    <Route path="*" element={<Error/>}></Route>
                </Routes>
            </Suspense>
        </HashRouter>
    )
}

export default Router;
